@import 'settings';
@import 'mixins';
@import 'fonts';
@import 'font-awesome';
@import 'bootstrap';
@import 'bootstrap-override';
@import 'bootstrap-addon';
@import 'nanoscroller';

@each $lang in it, en, de, es, fr, ro {
  .lang-#{$lang} {
    width: 24px;
    height: 18px;
    // workaround
    background-image: url(../images/flags/#{$lang}.svg);
    background-size: 24px 18px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    vertical-align: top;

    @include box-shadow(1px 1px 1px #c9c9c9);
  }
}

#login-container {
  @include reset-a($text-color);
}

.i-number {
  display: inline-block;
  width: 60px;
  border-left: none;
  border-right: none;
  text-align: center;
  vertical-align: middle;
}

.i-number-minus, .i-number-plus {
  @include no-outline;
  @extend .btn-default;
}

.add-to-cart-button {
  margin-bottom: 5px;
  width: 100%;
}

.not-found-message {
  margin: 40px 0;
}

#cookie-law {
  z-index: 10000;
  position: fixed;
  bottom: 0px;
  background-color: black;
  width: 100%;

  p {
    text-align: center;
    margin: 10px;
    color: white;

    a {
      cursor: pointer;
    }
  }
}

.subscribe-title {
  color: $green;
  font-weight: bold;
  text-transform: uppercase;
}

.subscribe-subtitle {
  text-transform: uppercase;
}

.text-gray {
  color: $gray !important;
}

.megamenu > ul > li a.special-color{
color:#8ac037;

}

@import 'components/breadcrumb';
@import 'components/prices';
@import 'components/header';
@import 'components/megamenu';
@import 'components/footer';
@import 'components/profile';
@import 'components/product';
@import 'components/catalog';
@import 'components/cart';
@import 'components/designers';
@import 'components/dialog';
@import 'components/checkout';
@import 'components/hovereffects';
@import 'components/subscribe';
