#checkout-go-shopping-btn {
  @media screen and (min-width: $screen-sm-min) {
    margin-top: 20px;
  }
  @media screen and (max-width: $screen-xs-max) {
    display: block;
  }
}

.step-number {
  border-radius: 50px;
  border: 1px solid $green;
  color: $green;
  display: inline-block;
  height: 32px;
  line-height: 30px;
  margin-right: 10px;
  text-align: center;
  width: 32px;

  &.active {
    background-color: $green;
    color: white;
  }
}

.btn-spacing-bottom {
  margin-bottom: 15px;
}
