
a {
  @include no-outline;
}

.form-control {
  @include no-outline;
  border: 1px solid #ccc;

  border-radius: 0;

  @include block-glowing-effect;

  &.error {
    border-color: red;
    @include block-glowing-effect($glowing-effect-color: fade-out(red, .25));
  }
}

.form-group, .form-group-subscribe {
  label.error, label.invalid {
    color: red;
    display: block;
    width: 100%;
    font-size: 9px;
    margin-top: 0;
    padding-top: 2px;
    padding-bottom: 0px;
  }

  .tags-form {
    label.invalid {
      display: none;
    }

    &.error {
      label.invalid {
        display: block;
      }
    }
  }
}

.checkbox {
  padding-left: 15px;

  label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 6px;
    @include user-select-and-callout;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label:before {
    border: 1px solid #CCC;
    content: '';
    cursor: pointer;
    height: 16px;
    left: -15px;
    position: absolute;
    top: 2px;
    width: 16px;

    background-image: url(../images/checkbox-checked.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 0px 0px;

    transition: all 0.15s ease-in-out 0s;
  }

  input[type="checkbox"]:checked + label:before {
    background-size: 14px 14px;
    border: 1px solid #FFF;
  }

}

.radio {
  padding-left: 15px;

  label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;
    @include user-select-and-callout;
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + label:before {
    border: 1px solid #CCC;
    border-radius: 10px;
    content: '';
    cursor: pointer;
    height: 16px;
    left: -15px;
    position: absolute;
    top: 2px;
    width: 16px;

    background-image: url(../images/radio-checked.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 0px 0px;

    transition: all 0.15s ease-in-out 0s;
  }

  input[type="radio"]:checked + label:before {
    background-size: 14px 14px;
  }

}

// bootstrap fix
.radio label, .checkbox label {
  line-height: 1.7;
}

.radio-btn-group {
  .radio-btn {
    display: inline-block;

    &:not(:first-child) {
      margin-left: -5px;
    }

    &:not(:first-child):not(:last-child) > .btn {
      border-radius: 0;
    }

    &:first-child > .btn {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    &:last-child > .btn {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}

.radio-btn {
  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + label {
  }

  input[type="radio"]:checked + label {
    @extend .active;
  }

}

.inline-checkbox {
  @extend .checkbox;
  display: inline-block;

  label {
    min-width: 60px;
    padding-right: 10px;
  }
}

.inline-radio {
  @extend .radio;
  display: inline-block;

  label {
    min-width: 60px;
    padding-right: 10px;
  }
}

.table {
  font-size: 14px;

  td {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  tr.selected {
    background-color: #F5F5F5;
  }
}

.list-inline {
  padding: 0px;
  margin: 0px;
  list-style: outside none none;
}


.alert-danger, .alert-success{
    position: fixed;
    width: 100%;
    top: 0;
    left:0;
    z-index: 99999999;

}
