#footer-navigation {

  margin-top: 50px;


  h4 {
    font-size: small;
    font-weight: bold;
    text-transform: uppercase;
  }

  .social-icons {
    position: relative;
    margin-top: -50px;
    text-align: center;
    li {
      margin: 0;
      padding: 0;
    }
  }

  .brands-logo {
    border-bottom: 2px solid darken($footer-line-color, 50%);
    padding: 20px 0;
  }

  .menu {
    ul {
      list-style-type: none;
      padding: 0;
    }

    padding: 20px 0;

    h4 {
      span.fa{
          display:none;
      }
    }
    @media screen and (max-width: $screen-xs-max) {
      h4 {
        margin-left: 20px;
        margin-right: 20px;
        line-height: 40px;
        border-bottom: 1px solid #cdcdcd;

        span.fa{
          display:inline-block;
        }

        &.lasth4{
          border-bottom:none!important;
        }


      }

      li a {
        display: block;
        line-height: 30px;
        width: 100%;
        margin-left: 20px;

        &:hover, &:focus {
          color: $green;
        }
      }
    }

    @media screen and (min-width: $screen-sm) {
      .collapse {
        display: block;
      }
    }
  }

  .payment-methods {
    width: 100%;
    max-width: $screen-xs / 2;
  }

  .subscribe-button {
    min-width: 250px;
    max-width: 100%;
    margin-top: 15px;
    text-transform: uppercase;

    @media screen and (max-width: $screen-xs-max) {
      width: 100%;
    }
  }

  @include reset-a($footer-fg-color);
  a {
    text-transform: capitalize;
    color:#5c5c5c;
  }

  a:hover {
    color:#928f8f;
  }




  .collapser {
    @extend .visible-xs;
    width: 100%;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

#footer-company {
  .logo {
    text-align: left;
    a {
      display:block;
    }
  }

  .copyright, .logo {
    line-height: 30px;
    padding: 10px 0;

    @media screen and (max-width: $screen-xs-max) {
      text-align: center;
    }
  }
}


#lhc_need_help_container {
  width: 285px!important;
  border-radius: 0px!important;
  background: #87C229!important;
  position: absolute!important;
  color: #fff!important;
  padding: 10px!important;
  border: 1px solid #87C229!important;
  margin-top: -90px!important;
  margin-left: -6px!important;

}

#lhc_need_help_triangle {
  display:none!important;
}


/* NL FORMS */
.conditions {
    font-size: 11px;
}

.iscnow {
    color: #171717;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    margin-top: 0px;
    display: block;
}

.subiscnow {
    color: #171717;
    font-weight: 600;
    font-size: 17px;
    opacity: 0.26;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 20px;
    display: block;
}

.form-wrapper {
    padding: 50px 30px 30px;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    margin-top: 100px;
    display: inline-block;

}
.form-wrapper .input-wrapper {
    margin-top: 0;
    display: block;
    width: 100%;
    float: left;
}
#subscribe-form-nl .form-input {
    float: none;
    width: 100%;
    margin: 0 0 15px;
    height: 59px;
    border: 1px solid #C7C7C7!important;
    border-radius: 4px!important;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.1);
    transition: 0.1s border,0.1s box-shadow;
    padding-left: 20px;
    font-size: 16px;
    font-weight: 100!important;
}

#subscribe-form-nl .form-submit {
    height: 40px;
    width: 100%;
    border-radius: 4px;
    border: none;
    background-color: #87c229;
    color: #f7f7f7;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 600;
    transition: background-color .2s;

}

.form-wrapper-footer {
    padding: 10px 10px 10px;
    background: none;
    border-radius: 6px;
    margin-top: 10px;
    display: block;

}

#subscribe-form-nl-footer .form-input {
    color: #464646;
    width: 100%;
    margin: 0 0 3px;
    height: 38px;
    border: 1px solid #C7C7C7 !important;
    border-radius: 4px !important;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
    transition: 0.1s border,0.1s box-shadow;
    padding-left: 20px;
    font-size: 16px;
    font-weight: 100 !important;
}
#subscribe-form-nl-footer .input-wrapper{
    width: 100%;
}

#subscribe-form-nl-footer .form-submit {
    height: 40px;
    width: 100%;
    border-radius: 4px;
    border: none;
    background-color: #87c229;
    color: #f7f7f7;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 600;
    transition: background-color .2s;

}

.conditions-footer ,
.conditions-footer a{
    font-size: 9px!important;
}

.form-submit:hover{
     background-color: #8ed026;

}
/* NL FORM END */


.extraban {
  background-color: #343434;
  box-sizing: border-box;
  position: fixed;
  width: 100% !important;
  display: block;
  bottom: -1px !important;
  letter-spacing: 0px;
  padding-bottom: 10px;
  padding-top: 10px;
  z-index: 1200;
  font-size: 11px;
  box-shadow: 0px 5px 17px #000;
  -webkit-transform: translate3d(0, 0, 0);
  transition: -webkit-transform .3s ease .8s;
    -webkit-transform:translateZ(0);
 -webkit-transform-style: preserve-3d;
 -webkit-backface-visibility: hidden;

}


.hide-extra {
/*-webkit-transform: translate3d(0, 64px, 0)!important;*/
}


.contatti{
  padding: 0px 30px;
  border-right: 1px solid #535353;
  display: inline-block;
  font-size: 22px;
  color: #ffffff;
  letter-spacing: 1px;
  font-weight: 600 !important;

}

.chat-container {
    display: none;
    width: 0;
    height: 100%;
    position: absolute;
    background: rgba(133, 196, 14, 0.91);
    -webkit-transform: translate3d(0, 100%, 0)!important;
    transition: -webkit-transform .2s ease .3s;
    -webkit-transform:translateZ(0);
   -webkit-transform-style: preserve-3d;
   -webkit-backface-visibility: hidden;
}

.show-chat {
  width:100%;
  top: 0;
  left:0;
  z-index:999999;
  display: block;
  overflow-y:hidden;
  position:fixed;
  -webkit-transform: translate3d(0, 0%, 0)!important;
}




.contatti a{
  color: #3d99d4;
}

.fa-commenting-o:before {

    color: #fff;
    font-size: 37px;
    margin-left: 20px;
    margin-top: 0px;
    display: inline-block;
    vertical-align: middle;
}




@media only screen and (max-width: 768px)  {
#footer-company {
  .logo {
    padding-bottom: 60px
  }
}
}
