@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/ProximaNova-Regular.eot?') format('eot'), url('../fonts/ProximaNova-Regular.woff') format('woff')
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/ProximaNova-Semibold.eot?') format('eot'), url('../fonts/ProximaNova-Semibold.woff') format('woff')
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: lighter;
  src: url('../fonts/ProximaNova-Light.eot?') format('eot'), url('../fonts/ProximaNova-Light.woff') format('woff')
}
