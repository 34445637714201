.cart-summary {
	hr + hr {
		display: none;
	}
}

.cart-details {

  .product {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $light-gray;
  }

  .product-thumbnail {
    display: block;
    text-align: center;

    img {
      width: 100%;
      max-width: 320px;
    }
  }

  .table {
    > thead > tr > th {
      border-bottom: 1px solid $table-border-color;
    }
    > tbody > tr > td{
      vertical-align: middle;
    }
    margin-bottom: 0;
  }

  .product-id {
    color: #000;
    text-decoration: none;
    
    .product-brand {
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
      padding-right: 5px;
    }

    .product-name {
      text-transform: capitalize;
      color: lighten($gray, 30%);
      padding-right: 5px;
      font-weight: normal;
    }
    
    .product-sku {
      text-transform: capitalize;
      color: lighten($gray, 30%);
      padding-right: 5px;
      font-size: 9px;
      font-weight: normal;
    }
  }

  .product-price-label {
    color: lighten($gray, 30%);
    font-size: 9px;
    text-transform: uppercase;
  }

  .product-price, .product-final-price {
    font-size: 24px;
  }

  .btn-cart-remove {
    margin-left: 4px;
    display: inline-block;
  }

}
