// theme variable definitions

$lightgreen: #87C229;
$green: #008F3F;
$gray: #4D4D4D;
$dark-gray: darken($gray, 10%);
$light-gray: lighten($gray, 50%);

// bootstrap parameters
$brand-primary: $green;
$primary-color: $green;
$default-bg-color: #FFFFFF;

$line-height-base: 1.43;

$font-family-sans-serif: "ProximaNova", Helvetica, Arial, sans-serif !default;

// header
$header-bg-color: #FAFAFA;

// footer
$footer-bg-color: #1A1A1A;
$footer-fg-color: #FFFFFF;
$footer-line-color: #C9C9C9;

$text-direction: ltr !default;
$default-float: left !default;
$opposite-direction: right !default;

$border-radius-base: 0px;
