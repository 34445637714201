// form styles

#subscribe-form {
  margin: 50px auto;
  text-align: center;
  position: relative;

  fieldset {
    background: white;
    border-radius: 10px;
    box-shadow: none;
    padding: 18px 30px 30px;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    // stacking fieldsets above each other
    position: relative;

    &:not(:first-of-type) {
      // Hide all except first fieldset
      display: none;
    }

    > .form-control {
      margin-bottom: 20px;
    }

    .form-group-subscribe {
      text-align: left;
    }
  }
}

.container-subscribe-form-page {
    background: #f7f7f7;
    margin-left: 0px;
    margin-right: 0px;
}

/*headings*/

.fs-title {
  font-size: 29px;
  color: #464646;
  margin-bottom: 22px;
}

.fs-subtitle {
  font-weight: normal;
  font-size: 13px;
  color: #666;
  margin-bottom: 20px;
}

.form-hr{
  padding-bottom: 15px;
}


.btn-brands-form {
    background-color: #8ac037;
    border-color: #8ac037;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    padding: 13px;
}

.btn-brands-form:hover {
    background-color: #8bbe42;
    color:#fff;

}


/* show/hide psw */


.eye {
    position: absolute;
    right: 40px;
    margin-top: 0px;
}





#showHide {
width: 30px;
height: 30px;
top: 0px;
position: absolute;
right: 0;
float: right;
margin-right: 0;
z-index: 3;
opacity: 0;
}

.eye label {
    background-image: url(../images/eye-stash.png);
    width: 30px;
    height: 30px;
    float: right;
    top: 4px;
    position: relative;
    right: 6px;
    margin-right: 0;
    background-size: cover;
    z-index: 2;
    opacity: 0.8;
    transition: all 0.1s ease-in-out;
}

.eye label.stash {
    background-image: url(../images/eye.png);

}

.offline .fa-comment:before {
  color:rgba(144, 144, 144, 0.47);
}
