#profile-container {
  margin-top: 20px;

  .save-button {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
  }

  #profile-nav {
    > li {
      border-bottom: 2px solid transparent;

      &.active {
        border-bottom: 2px solid $green;
        font-weight: bold;

        > a {
          color: $green;
          background-color: transparent;

          &:hover {
            background-color: transparent;
            color: darken($green, 20%);
          }
        }
      }

      > a {
        border-radius: 0;
        color: $text-color;
        text-transform: capitalize;

        &:hover {
          background-color: transparent;
          color: darken($green, 20%);
        }
      }
    }
  }

  .paginator {
    text-align: center;
  }

  .roles {
    .role {
      text-transform: capitalize;
    }
  }
}

.order-inner-link {
  > a {
    display: block;
  }
}

.order-row {
  cursor: pointer;
}

.order-controls {
  display: none;

  &:hover {
    background-color: transparent !important;
  }

  .order-controls-row {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    .label {
      line-height: 33px; // button height
      text-transform: capitalize;
    }
  }

  .order-controls-return {
    > thead > tr > th {
      text-transform: capitalize;
    }
    > tbody > tr > td {
      vertical-align: middle;
    }
  }
}

.table > tbody > tr.order-controls {
  border-left: 3px solid $green;
  > td {
    padding-left: 18px;
  }
}

.new-return-request{
  display: none;
}

.row-returned-goods {
  dl {
    margin-bottom: 0;
  }

  .cancel-request {
    @media screen and (min-width: $screen-sm-min) {
      margin-top: 42px;
    }
    margin-top: 15px;
  }
}
