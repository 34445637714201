.dialog {
  background-color: #fff;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 40px;

  .dialog-title {
    color: #008F3F;
    font-size: 20px;
    border-bottom: 1px solid #000;
    padding-bottom: 5px;
    font-weight: bold;
  }

  .dialog-subtitle {
    font-size:16px;
    font-style: italic;
    color:#333333;
  }
}

.dialog-desclaimer {
  font-size: 12px;
}
